<template>
  <div>
    <PageTitle>PrivacyPolicy</PageTitle>
  </div>
</template>

<script>
import PageTitle from '@/components/generic/PageTitle.vue'
export default {
  components: { PageTitle },
}
</script>

<style lang="scss" scoped></style>
