<template>
  <button
    type="button"
    class="tabe__bookmark"
    :class="{ 'tabe__bookmark--selected': value }"
    @click="toggle"
    v-b-tooltip.hover.noninteractive
    :title="value ? 'Bookmarked' : `Bookmark this ${name}`"
  >
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="940.688px"
      height="940.688px"
      viewBox="0 0 940.688 940.688"
      style="enable-background: new 0 0 940.688 940.688"
      xml:space="preserve"
    >
      <path
        id="bookmark-icon"
        d="M885.344,319.071l-258-3.8l-102.7-264.399c-19.8-48.801-88.899-48.801-108.6,0l-102.7,264.399l-258,3.8
													c-53.4,3.101-75.1,70.2-33.7,103.9l209.2,181.4l-71.3,247.7c-14,50.899,41.1,92.899,86.5,65.899l224.3-122.7l224.3,122.601
													c45.4,27,100.5-15,86.5-65.9l-71.3-247.7l209.2-181.399C960.443,389.172,938.744,322.071,885.344,319.071z"
      />
    </svg>
  </button>
</template>

<script>
import { BIconStar, BIconStarFill } from 'bootstrap-vue'
import px from 'vue-types'

export default {
  name: 'BookmarkToggle',
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    name: px.string,
    value: px.bool.def(false),
  },
  computed: {
    icon() {
      return this.value ? BIconStarFill : BIconStar
    },
  },
  methods: {
    toggle() {
      this.$emit('update', !this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.tabe__bookmark {
  // reset
  padding-right: 0;
  padding-left: 0;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  border: none;
  background-color: transparent;

  // styling
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    font-weight: 400;
    font-size: 10px;
    color: $primary;
    line-height: 1;
  }

  img,
  svg {
    margin-bottom: 3px;
  }

  &--selected {
    svg #bookmark-icon {
      fill: $primary;
      stroke: $primary;
    }
  }

  img,
  svg {
    width: 18px;
    height: 18px;
  }

  #bookmark-icon {
    fill: transparent;
    stroke: $primary;
    stroke-width: 5%;
  }

  &:not(.tabe__bookmark--selected) {
    &:hover {
      #bookmark-icon {
        fill: $primary;
      }
    }
  }
}
</style>
